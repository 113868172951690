import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";

const PostByTopic = ({ topics, className = "" }) => {
  return (
    <div className={`bg-gray-100 p-3 rounded-lg ${className}`}>
      <h3 className="mb-2 text-xl">Post by topic</h3>
      <div className="h-[1px] bg-gray-300"></div>
      <div className="flex flex-col items-start gap-2 my-2 text-primary-blue-dark">
        {topics.map((topic) => (
          <Link to={topic.link} key={topic.topic}>
            {topic.topic} ({topic.count})
          </Link>
        ))}
      </div>
    </div>
  );
};

PostByTopic.propTypes = {
  className: PropTypes.string,
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      topic: PropTypes.string,
      count: PropTypes.number,
    })
  ),
};

PostByTopic.defaultProps = {
  className: "",
};

export default PostByTopic;
