import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { BiChevronRight } from "react-icons/bi";

const RecentPost = ({ posts, className = "" }) => {
  return (
    <div className={`bg-gray-100 p-3 rounded-lg ${className}`}>
      <h3 className="my-2 text-xl">Recent post</h3>
      <div className="h-[1px] bg-gray-400"></div>
      <div className="flex flex-col gap-3 my-3">
        {posts.map((post) => (
          <div key={post.id} className="flex items-center gap-2 pb-3 border-b-2">
            <GatsbyImage
              className="recent-post-wrapper"
              imgClassName="recent-post-image"
              image={post.image}
            ></GatsbyImage>
            <div className="w-[65%] xl:w-[80%]">
              <h3 className="text-sm font-medium">{post.title}</h3>
              <Link className="text-sm flex items-center font-bold text-primary-blue-dark" to={post.link}>
                Read more <BiChevronRight size="20px" fontWeight="bold" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

RecentPost.propTypes = {
  className: PropTypes.string,
  posts: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.string,
      title: PropTypes.string,
      link: PropTypes.string,
    })
  ),
};

RecentPost.defaultProps = {
  className: "",
};

export default RecentPost;
