import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { BiChevronLeft } from "react-icons/bi";
import Layout from "../containers/global/layout";
import BreadCrumb from "../containers/BreadCrumb";
import Container from "../utils/Container";

import BlogTag from "../components/BlogTag";
import LinkedIn from "../images/linkedin.png";
import HubspotForm from "../components/HubspotForm";
import PostByTopic from "../components/PostByTopic";
import RecentPost from "../components/RecentPost";
import EvolvingInterprises from "../containers/EvolvingEnterprises";

import { FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa";

import { renderContentfulText } from "../utils/RichTextUtil";

const Blog = ({ data }) => {
  const thisBlog = data.thisBlog;
  const evolvingEnterpriseData = data.evolvingEnterpriseData.nodes[0];

  const allTopics = data.allTopics.nodes;
  const topicsList = [];
  allTopics.forEach((topic) => {
    if (topic.blog) {
      topicsList.push({
        topic: topic.topicName,
        count: topic.blog.length,
        link: `/category/${topic.slug}`,
      });
    }
  });

  const recentPosts = data.recentPosts.nodes;
  const recentPostsList = [];
  recentPosts.forEach((recentPost) => {
    recentPostsList.push({
      image: recentPost.coverImage.gatsbyImageData,
      title: recentPost.title,
      link: `/blog/${recentPost.slug}`,
    });
  });

  return (
    <Layout>
      <section className="relative -z-10">
        <h2 className="w-full md:w-1/2 px-[10%] absolute z-20 text-white text-2xl top-1/2 -translate-y-1/2">
          {thisBlog.title}
        </h2>
        <GatsbyImage
          imgClassName="block absolute h-[420px] w-full"
          className="h-[420px] w-full"
          image={thisBlog.coverImage.gatsbyImageData}
        ></GatsbyImage>
        <div className="absolute inset-0 z-10 bg-black opacity-50"></div>
      </section>
      <Container>
        <BreadCrumb
          breadCrumbItems={[
            {
              text: "home",
              link: "/",
            },
            {
              text: "resources",
              link: "/resources",
            },
            {
              text: "blog",
            },
          ]}
        ></BreadCrumb>

        <section className="flex flex-col gap-5 md:flex-row">
          <section className="md:w-[65%]">
            <div className="py-4">{renderContentfulText(thisBlog.body)}</div>
            <div className="flex flex-col items-start gap-2">
              {thisBlog.topic.map((topic) => (
                <BlogTag link={topic.slug} key={topic.topicName}>
                  {topic.topicName}
                </BlogTag>
              ))}
            </div>

            <div className="bg-gray-300 w-1/5 mt-10 h-[1px] my-1"></div>
            <div className="mb-16">
              <h4 className="text-[#4d4d4d] font-bold mb-2">Share this article</h4>
              <div className="flex gap-5">
                <FaFacebookF className="text-gray-500" size="2.3rem" />
                <FaTwitter className="text-gray-500" size="2.3rem" />
                <FaLinkedinIn className="text-gray-500" size="2.3rem" />
              </div>
              <Link
                to="/resources/blog/"
                className="inline-flex items-center text-lg font-bold text-white bg-[#0075c9] px-16 py-2 my-3 rounded-3xl"
              >
                <BiChevronLeft /> Back
              </Link>
            </div>
          </section>
          <section className="md:w-[35%]">
            <section className="mb-5 md:my-0 md:mb-3">
              <p className="font-bold text-[#333e47]">Follow Us</p>
              <img src={LinkedIn} className="w-[7%] mb-3"></img>
              <HubspotForm
                portalId="1750400"
                formId="542ff168-80b1-4e99-9980-42222ba880ca"
                containerId="hbspt-blog-subscribe"
              ></HubspotForm>
            </section>
            <PostByTopic topics={topicsList}></PostByTopic>
            <RecentPost className="mt-4" posts={recentPostsList}></RecentPost>
          </section>
        </section>
      </Container>
      <EvolvingInterprises
        bgImg={evolvingEnterpriseData.desktopImage.gatsbyImageData}
        mobImg={evolvingEnterpriseData.mobileImage.gatsbyImageData}
        title={evolvingEnterpriseData.heading}
        leftText={evolvingEnterpriseData.leftText}
        rightText={evolvingEnterpriseData.rightText}
        bottomText={evolvingEnterpriseData.bottomText}
      />
    </Layout>
  );
};

Blog.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.shape({
    numPages: PropTypes.number,
    currentPage: PropTypes.number,
  }),
};

export default Blog;

export const query = graphql`
  query BlogPage($blogId: String!) {
    thisBlog: contentfulBlog(id: { eq: $blogId }) {
      title
      body {
        raw
      }
      topic {
        topicName
        slug
      }
      coverImage {
        gatsbyImageData
      }
    }

    allTopics: allContentfulBlogTopic {
      nodes {
        topicName
        blog {
          id
        }
        slug
      }
    }

    recentPosts: allContentfulBlog(sort: { fields: createdAt, order: ASC }, limit: 3) {
      nodes {
        coverImage {
          gatsbyImageData
        }
        title
        slug
      }
    }

    evolvingEnterpriseData: allContentfulEvolvingEnterprises(
      filter: { metadata: { tags: { elemMatch: { name: { in: ["page: entomo_community", "region: global"] } } } } }
    ) {
      nodes {
        bottomText
        leftText
        rightText
        heading
        desktopImage {
          gatsbyImageData(width: 1200, placeholder: BLURRED, formats: WEBP, quality: 50)
        }
        mobileImage {
          gatsbyImageData(width: 1200, placeholder: BLURRED, formats: WEBP, quality: 50)
        }
      }
    }
  }
`;
