import React from "react";
import PropTypes from "prop-types";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Bold = ({ children }) => <span className="font-bold">{children}</span>;

Bold.propTypes = {
  children: PropTypes.object,
};
const Text = ({ children }) => <p>{children}</p>;

Text.propTypes = {
  children: PropTypes.object,
};

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

export const renderContentfulText = (body) => {
  return renderRichText(body, options);
};
