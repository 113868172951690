import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

const BlogTag = ({ children, link }) => {
  return (
    <Link
      to={`/category/${link}`}
      className="group transition-colors hover:text-white  hover:bg-primary-blue-dark inline-block relative bg-gray-200 px-6 rounded-md text-gray-400"
    >
      <div className="absolute w-2 h-2 rounded-full shadow-inner top-1/2 left-2 -translate-y-1/2 bg-white"></div>
      {children}
      <div className="transition-colors group-hover:border-l-primary-blue-dark arrow-right"></div>
    </Link>
  );
};

BlogTag.propTypes = {
  children: PropTypes.object,
  link: PropTypes.string,
};

export default BlogTag;
